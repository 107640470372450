import React from "react"

import { Block } from "baseui/block"
import { SEOPage } from "../../components/SEO"
import Layout from "../../components/layout"
import BannerPlain from "../../containers/BannerPlainSection"
import AMLcase from "../../containers/FraudUseCase/AMLCase"
import BankingCaseStudy from "../../containers/FraudUseCase/BankingCaseStudy"
import DiscoverTransactionMonitoring from "../../containers/FraudUseCase/BlocklynxForFraud"
import MotifForFraud from "../../containers/FraudUseCase/MotifForFraud"
import { Newsletter } from "../../containers/Newsletter"
import BannerBG from "../../images/banner-blocklynx.png"

export default () => {
  return (
    <Layout>
      <SEOPage
        title="Fraud Detection Solutions"
        description="Discover the advantages of network analytics for fraud detection - higher accuracy, lower false positive rates and greater model explainability"
      />
      <BannerPlain
        title="Network Analytics for Fraud Detection"
        subTitle="Higher accuracy, lower false positive rates with explainability built in"
        background={BannerBG}
        backgroundSize="cover"
        backgroundColor="#0C6B58"
      />
      <AMLcase />
      <BankingCaseStudy />
      <MotifForFraud />
      <DiscoverTransactionMonitoring />
      <Block marginBottom="150px" />
      <Newsletter />
    </Layout>
  )
}

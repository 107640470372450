import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Img from "gatsby-image"
import { buildFluidImageData } from "@imgix/gatsby"
import {
  Title,
  SubTitle,
  Description,
  ItemTitle,
} from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const BankingCaseStudy = () => {
  const Data = useStaticQuery(graphql`
    query {
      imgixImage(url: "/uploads/bank-security.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block paddingTop="100px" paddingBottom="100px" id="discover-blocklynx">
      <Container >
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell order={[2, 2, 1]} span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <SubTitle>Case Study</SubTitle>
              <Title>Deploying Network Analytics to Combat Fraud</Title>
              <Description marginBottom="12px">
                A retail bank in South East Asia employed machine learning for
                case prioritization and financial crime risk assessment.
              </Description>
              <ItemTitle>Outcomes</ItemTitle>
              <Description>
                1) Improved employee productivity with less time spent on false
                positive cases
              </Description>
              <Description>
                2) Better detection of indirect relationships and hidden
                connections
              </Description>
              <Description marginBottom="30px">
                3) New fraud cases are caught automatically without a need to
                update rules
              </Description>
              <Link to="/contact-us">
                <Button>Contact Us for a Free Consultation</Button>
              </Link>
            </Block>
          </Cell>
          <Cell order={[1, 1, 2]} span={[12, 6]}>
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Img
                fluid={{ ...Data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="Blocklynx Image"
              />
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default BankingCaseStudy
